import extraGlobalState from '@/utils/extraGlobalState';
import { getNtoccOssKey } from '@/utils/oss';
import { uploadFileToOss } from '@gui/utils/es/oss-utils';
import { Form, Grid, Upload } from '@gui/web-react';
import { IconClose, IconEye, IconFaceFrownFill, IconFileAudio, IconLoading, IconUpload } from '@gui/web-react/icon';
import classNames from 'classnames';
import { ERROR, EXAMPLE_IMAGES, WARN } from 'constants';
import _ from 'lodash';
import qs from 'qs';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { compressFile, fetchApi, fetchJAVA, getG7ImageURL, showInfo, viewImage } from 'utils';
import { v4 as uuidv4 } from 'uuid';
import { prefixCls } from './index.scss';

const { Row } = Grid;
const { Col } = Grid;

const FormItem = Form.Item;

const getFileName = url => {
  // 生成完整地址
  if (url.indexOf('http') === -1) {
    url = `${window.location.protocol}//${window.location.host}${url}`;
  }
  const { pathname, search = '' } = new window.URL(url);
  const data = qs.parse(search, { ignoreQueryPrefix: true });
  if (data && data.req) {
    const req = JSON.parse(data.req);
    return req.name;
  }
  if (!pathname) return url;
  const paths = pathname.split('/');
  if (!paths.length) return url;
  return paths[paths.length - 1];
};

const ImageForm = (props, ref) => {
  const {
    images = [], // 图片项
    onChange,
    onRemove = () => {},
    initialValue, // 默认展示（或者回显）的图片
    type, // TODO: 抽离构建函数。图片类型，用于默认图片构建url
    showExample = true, // 是否显示示例图片
    url = '/Basic/Image/uploadImages', // 上传后端服务地址
    isOss, // 是否上传oss (网货oss)
    maxSize = 5,
    errValue,
    onPreviewCallback, // 点击预览的回调，提供给调用方自定义一些逻辑
    useCYTOss = false, // 是否上传到财运通oss，isOss为false时，这个参数生效
  } = props;
  const [form] = Form.useForm();

  const currFinalFile = useRef(null);
  const [innerErrValue, setInnerErrValue] = useState({});

  useEffect(() => {
    if (!initialValue) return;
    const initValue = {};
    const allFields = form.getFields();

    async function setInitValue(key, data, index = 0) {
      if (_.isArray(data)) {
        if (data.length === 0) {
          _.set(initValue, `${key}.value`, []);
        } else {
          data.forEach((item, i) => setInitValue(key, item, i));
        }
        return;
      }
      let src;
      let config = {};
      if (_.isString(data)) {
        src = data; // TODO: 只有URL时，回显name
      } else if (_.isPlainObject(data)) {
        config = { type, ...data };
        src = await getG7ImageURL(config);
      }

      // 1 审核中 2 审核成功 3失败 有2组图片的时候只展示第二组的原因
      if (config.auditStatus !== 2 && !errValue?.[key]) {
        _.set(initValue, `${key}.error`, { message: config.failureReason });
      }

      const currentValue = _.get(allFields, `${key}.value`, []);
      const exist = currentValue.find(item => item.url === src);
      if (!exist && src) {
        _.set(initValue, `${key}.value.${index}`, { url: src, uid: src, name: getFileName(src), ...config });
      }
    }
    if (errValue) {
      setInnerErrValue(errValue);
    }
    Promise.all(Object.entries(initialValue).map(async entries => await setInitValue(...entries))).then(() => {
      form.setFields(initValue);
    });
  }, [initialValue, type]);

  const resCache = useRef({});

  useImperativeHandle(ref, () => ({
    form,
    fileCache: resCache,
  }));

  // TODO: 司机、车辆信息里面原来用到了这个数据，改造为oss 后，都不需要了，改完后再把这块相关逻辑删除
  const setResCache = (field, key, value) => {
    const { current } = resCache;
    const imgList = current[field] || [];

    const index = imgList.findIndex(item => item.uniqueName === key);
    if (index > -1) {
      imgList.splice(index);
    }

    if (value) {
      imgList.push(value);
    }

    resCache.current = {
      ...current,
      [field]: imgList,
    };
  };

  const getResCache = (field, key) => {
    const imgList = resCache.current[field] || [];
    if (!key) return imgList;
    return imgList.find(item => item.uniqueName === key);
  };

  // 上传到财运通OSS
  const uploadCYTOSS = async (imageItem, option) => {
    const { onError, onSuccess, file, onProgress } = option;
    const { key: imageKey } = imageItem;
    let fetch;
    try {
      fetch = uploadFileToOss({
        file,
        request: fetchConfig => {
          const { params } = fetchConfig;
          return fetchJAVA('/cmm-basic/resource/getAuthKey', {
            method: 'POST',
            ...params,
          });
        },
        requestConfig: {
          params: {
            body: {
              req: {
                type,
                group_id: window?.group_id,
              },
            },
          },
        },
        formateReceiveData: resData => {
          const { path, uploadData } = resData;
          const fileName = path.split('/').pop();
          return {
            name: fileName,
            path,
            showName: uploadData.name,
          };
        },
        onProgress,
      });
      const res = await fetch;
      setResCache(imageKey, file.uniqueName, { ...res, uniqueName: file.uniqueName, name: file.name });
      onSuccess(res, fetch);
    } catch (error) {
      onError(error);
    }
    return {
      abort() {},
    };
  };

  const uploadOSS = async (imageItem, option) => {
    const { onError, onSuccess, file, onProgress } = option;
    const { key: imageKey } = imageItem;

    // 确保获取到oss配置
    const ossConfig = await getNtoccOssKey();
    if (!ossConfig) {
      const errmsg = '上传图片失败，请稍后重试或联系售后支持！';
      showInfo(WARN, errmsg);
      return onError(errmsg);
    }

    const imgName = `${file.uniqueName || file.name}`;
    const ossKey = `${ossConfig.dir}${imgName}`;

    const reqObj = {
      key: ossKey,
      policy: ossConfig.policy,
      OSSAccessKeyId: ossConfig.accessId,
      signature: ossConfig.signature,
      success_action_status: '200',
      name: imgName,
    };
    const formData = new FormData();
    Object.keys(reqObj).forEach(ikey => formData.append(ikey, reqObj[ikey]));
    formData.append('file', file, imgName); // TODO: file name?

    const xhr = new XMLHttpRequest();
    if (xhr.upload) {
      xhr.upload.onprogress = function (event) {
        let percent;
        if (event.total > 0) {
          percent = (event.loaded / event.total) * 100;
        }
        onProgress(parseInt(percent, 10), event);
      };
    }
    xhr.onerror = function error(e) {
      showInfo(ERROR, '上传图片失败，请稍后重试或联系售后支持！');
      onError(e);
    };
    xhr.onload = function onload() {
      if (xhr.status < 200 || xhr.status >= 300) {
        return onError(xhr.responseText);
      }
      setResCache(imageKey, file.uniqueName, { ...ossConfig, ossKey, uniqueName: file.uniqueName, name: file.name });
      onSuccess(xhr.responseText, xhr);
    };

    xhr.open('post', ossConfig.host, true);
    xhr.send(formData);

    return {
      abort() {
        xhr.abort();
      },
    };
  };

  const uploadBackend = async (imageItem, option) => {
    const { onError, onSuccess, file } = option;
    const { key: imageKey, asynReq } = imageItem;

    const originFile = await compressFile(file);

    function blobToFile(theBlob, fileName) {
      return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type });
    }
    const finalFile = blobToFile(originFile, `${String(file.uniqueName).replace(/\./g, '')}.jpeg`);

    currFinalFile.current = finalFile;

    const _formData = new FormData();
    _formData.append('img[]', finalFile);
    const req = { ...asynReq };
    req.t = new Date().valueOf();
    _formData.append('req', JSON.stringify(req));
    const conf = {
      method: 'POST',
      body: _formData,
      credentials: 'include',
    };
    let fetch;
    try {
      fetch = fetchApi(url, conf);
      const res = await fetch;
      if (res.errno) {
        showInfo(ERROR, '上传图片失败，请稍后重试或联系售后支持！');
        onError(res);
      } else {
        const imageInfo = _.get(res, 'res.image_info', {});
        let img = _.get(imageInfo, 'img.0', imageInfo);
        if (typeof img === 'string') {
          img = {
            url: img,
          };
        }
        setResCache(imageKey, file.uniqueName, { ...img, uniqueName: file.uniqueName, finalFile });
        onSuccess(res, fetch);
      }
    } catch (e) {
      showInfo(ERROR, '上传图片失败，请稍后重试或联系售后支持！');
      onError(e);
    }
    return {
      abort() {
        fetch && fetch.abort && fetch.abort();
      },
    };
  };

  const handleRequest = async (item, option) => {
    let request;
    if (isOss) {
      const useNewWayEnabled = extraGlobalState.getState('isNewWayEnabled');
      const useNewCYTOss = useNewWayEnabled && useCYTOss;
      request = useNewCYTOss ? await uploadCYTOSS(item, option) : await uploadOSS(item, option);
    } else {
      request = await uploadBackend(item, option);
    }
    return request;
  };

  const handleRemove = (imageKey, fileList, file) => {
    onRemove &&
      onRemove({
        field: imageKey,
        action: 'del',
        fileList,
        file,
      });
  };

  const handleChange = (imageKey, fileList, file) => {
    file.originFile = file.originFile || {}; // 原File对象
    // eslint-disable-next-line no-shadow
    const { type = 'image/png' } = file.originFile;

    if (!file.uniqueName) {
      // eslint-disable-next-line no-shadow
      const fileType = type.split('/')[1] || '';
      const uniqueName = `${uuidv4()}.${fileType}`;
      file.uniqueName = uniqueName;
      file.originFile.uniqueName = uniqueName;
    }
    if (file.status !== 'done') {
      setResCache(imageKey, file.uniqueName);
    }
    const isDelete = fileList.length === 0;
    const isAdd = fileList.length > 0 && file.status === 'done';
    let opType = 'unknown';
    if (isAdd) opType = 'add';
    if (isDelete) opType = 'del';
    onChange &&
      onChange({
        field: imageKey,
        action: opType,
        responseList: getResCache(imageKey),
        response: getResCache(imageKey, file.uniqueName),
        fileList,
        file,
      });
    return fileList;
  };
  const isAcceptFile = file => {
    if (!file?.type.includes('image/') || file.type === 'image/webp') {
      showInfo(ERROR, '上传文件格式不正确，请重新上传！');
      return false;
    }
    return true;
  };
  const handlePreview = (imageKey, file) => {
    const fileList = form.getFieldValue(imageKey) || [];
    // eslint-disable-next-line no-shadow
    function buildUrl(file) {
      file.src = file.url || window.URL.createObjectURL(file.originFile);
    }
    buildUrl(file);
    fileList.forEach(buildUrl);
    viewImage(file, fileList);
    onPreviewCallback?.();
  };

  const openNewImgDialog = async imageKey => {
    const imgObj = innerErrValue[imageKey];
    imgObj.src = await getG7ImageURL(imgObj);
    imgObj.uid = imgObj.fullName;
    imgObj.url = imgObj.fullName;

    if (imgObj.url) {
      viewImage(imgObj, [imgObj]);
    } else {
      showInfo(ERROR, '获取图片地址失败，请稍后重试');
    }
  };

  const renderItem = () => {
    return (
      <Row>
        {images.map(item => {
          const isOnePic = !item.maxCount || item.maxCount === 1;
          const gridLayout = {
            span: isOnePic ? 8 : 24,
            // offset: isOnePic ? 1 : 0,
          };
          return (
            <Col {...gridLayout}>
              <FormItem
                className={classNames('image-form-item', { required: item.required })}
                label={`${item.txt}${!item.disabled && !isOnePic ? `(最多${item.maxCount}张)` : ''}`}
                field={item.key}
                key={item.key}
                disabled={item.disabled}
                triggerPropName="fileList"
                requiredSymbol={false}
                rules={[
                  {
                    type: 'array',
                    maxLength: item.maxCount || 1,
                    required: item.required,
                    message: `${item.txt}必填`,
                  },
                ]}
              >
                <Upload
                  listType="picture-card"
                  name="files"
                  drag
                  multiple
                  accept="image/*"
                  limit={{ maxCount: item.maxCount || 1, hideOnExceedLimit: true }}
                  beforeUpload={(...param) => {
                    const isAccept = isAcceptFile(...param);
                    if (!isAccept) {
                      return false;
                    }
                    item.beforeUpload && item.beforeUpload(...param);
                  }}
                  showUploadList={{
                    // Please dont remove this comment
                    reuploadIcon: <IconUpload />,
                    cancelIcon: <IconLoading />,
                    fileIcon: <IconFileAudio />,
                    removeIcon: <IconClose />,
                    previewIcon: <IconEye />,
                    errorIcon: <IconFaceFrownFill />,
                  }}
                  onChange={(...param) => handleChange(item.key, ...param)}
                  onRemove={(...param) => handleRemove(item.key, ...param)}
                  onDrop={e => {
                    const isAccept = isAcceptFile(e.dataTransfer.files[0]);
                    if (isAccept) {
                      handleChange(item.key, [...e.dataTransfer.files], e.dataTransfer.files[0]);
                    }
                  }}
                  customRequest={(...option) => handleRequest(item, ...option)}
                  onPreview={file => handlePreview(item.key, file)}
                >
                  <div className="trigger">
                    {(item.showExample || showExample) && item.example && (
                      <img
                        src={EXAMPLE_IMAGES[item.example] || item.example}
                        style={{ width: '200px', height: '127px', marginBottom: '8px' }}
                      />
                    )}
                  </div>
                </Upload>
              </FormItem>
              <div style={{ marginBottom: '20px' }}>
                {innerErrValue[item.key] && innerErrValue[item.key]?.auditStatus !== 2 ? (
                  <div>
                    <span className="more-pic-font">{innerErrValue[item.key].failureReason}</span>
                    <span className="more-pic-link" onClick={() => openNewImgDialog(item.key)}>
                      查看图片
                    </span>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </Col>
          );
        })}
      </Row>
    );
  };
  return (
    <Form form={form} layout="vertical" className={prefixCls}>
      {renderItem()}
    </Form>
  );
};

const ImageFormComponent = forwardRef(ImageForm);

export default ImageFormComponent;
