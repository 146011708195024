export const invoiceData = {
  title: {
    eventNames: ['onChange'],
    labelProps: { children: '发票抬头' },
    otherProps: { disabled: false, type: 'text', placeholder: '', defaultValue: '' },
    type: 'Input',
  },
  tfn: {
    eventNames: ['onChange'],
    labelProps: { children: '税号' },
    otherProps: { disabled: false, type: 'text', placeholder: '', defaultValue: '' },
    type: 'Input',
  },
  address: {
    eventNames: ['onChange'],
    labelProps: { children: '单位地址' },
    otherProps: { disabled: false, type: 'text', placeholder: '', defaultValue: '' },
    type: 'Input',
  },
  open_bank: {
    eventNames: ['onChange'],
    labelProps: { children: '开户银行' },
    otherProps: { disabled: false, type: 'text', placeholder: '', defaultValue: '' },
    type: 'Input',
  },
  bank_account_num: {
    eventNames: ['onChange'],
    labelProps: { children: '银行账号' },
    otherProps: { disabled: false, type: 'text', placeholder: '', defaultValue: '' },
    type: 'Input',
  },
  phone: {
    eventNames: ['onChange'],
    labelProps: { children: '电话号码' },
    otherProps: { disabled: false, type: 'text', placeholder: '', defaultValue: '' },
    type: 'Input',
  },
  tax_rate: {
    eventNames: ['onChange'],
    labelProps: { children: '税率' },
    otherProps: { disabled: false, type: 'text', placeholder: '', defaultValue: '' },
    type: 'Input',
  },
  invoice_content: {
    eventNames: ['onChange'],
    labelProps: { children: '开票内容' },
    otherProps: { disabled: false, type: 'text', placeholder: '', defaultValue: '' },
    type: 'Input',
  },
  invoice_comment: {
    eventNames: ['onChange'],
    labelProps: { children: '开票备注' },
    otherProps: { disabled: false, type: 'text', placeholder: '', defaultValue: '' },
    type: 'Input',
  },
  need_invoice: {
    otherProps: { defaultValue: '1' },
  },
};
