/**
 
 * 在途费用按单审核
 */

import { customDownload, getSpecialGalleryGroup } from '@/components/utilcomponents/dialog/ImageViewer/utils.js';
import { withPopUpI18n } from '@/utils/i18n/context';
import { Gallery } from '@gui/web-react';
import {
  Button,
  CardBox,
  CheckBox,
  Icon,
  Load,
  MenuBtn,
  ModalDialog,
  PopUp,
  PureTextarea,
  SlideDrager,
  Table,
  Tips,
} from 'components';
import { auditLog } from 'components/commoncomponents/listOperation/approvalOp';
import { printFn, setHeader } from 'components/commoncomponents/listOperation/commonOp';
import { initEmt } from 'components/commoncomponents/listOperation/financialOp';
import { waybillOrderView } from 'components/commoncomponents/listOperation/orderNumOp';
import { batchView } from 'components/commoncomponents/listOperation/truckBatchOp';
import { ImageTag } from 'components/commoncomponents/listOperation/truckOp/batchTaskAdd';
import SimilarFee from 'components/commoncomponents/similarFee';
import { DYNAMIC_BUTTON_URL, ERROR, WARN } from 'constants';
import _ from 'lodash';
import { batchFeeView } from 'operation/listOperation/feeOp.js';
import { roundTripDetail } from 'operation/listOperation/reportsOp.js';
import { batchPaymentApply } from 'operation/listOperation/taskOp';
import { setTable } from 'pages/ListPage/action';
import { PropTypes } from 'prop-types';
import React from 'react';
import {
  bem,
  ft,
  getPageKey,
  hasPermission,
  post,
  postJAVA,
  proxyListPage,
  showInfo,
  Storage,
  throttle,
  typeIs,
} from 'utils';
import { dataAnalyticTrack } from 'utils/dataAnalytic';
import { sentryReporter } from 'utils/sentryHelper';
import { v4 as uuidv4 } from 'uuid';
import BatchOp from '../BatchOp';
import { prefixCls } from './index.scss';

const cls = bem(prefixCls);

const BATCH_IMAGE_UUID = uuidv4();

const imgTagMap = {
  pic: '图片',
  payment_pic: '付款照片',
};

class TaskInTransitFeeAuditByBatch extends React.Component {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    auditList: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    i18n: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    pageInfo: PropTypes.object,
    onSuccess: PropTypes.func,
    close: PropTypes.func,
    dataType: PropTypes.string, // select: 勾选数据 list: 未勾选数据
    justShowBatchDetail: PropTypes.bool,
  };
  static defaultProps = {
    justShowBatchDetail: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      auditList: _.cloneDeep(props.auditList),
      batchData: {
        data: [],
        header: {},
        total: {},
      },
      totalData: {
        data: [],
        enumerations: {},
        header: {},
        total: {},
        collapsed: false,
        showText: false,
      },
      feeData: {
        data: [],
        enumerations: {},
        header: {},
        total: {},
      },
      remark: '',
      isShowReturn: localStorage.getItem(`is_show_return.${window.user_id}`) === 'true', // 记忆用户之前的选择
      showImg: false,
      imgIndex: 0,
      imgList: [],
      subList: [],
      canAudit: false, // 是否可审核
      update: false, // 是否有审批
      loading: false,
      picTag: '图片',
    };
  }
  componentDidMount() {
    this.getData();
    this.getPrintMenu();
  }
  // 初始化数据
  getData = async () => {
    const { isShowReturn, auditList } = this.state;
    const { dataType } = this.props;
    const req = {
      page_num: 1,
      page_size: null,
      filter: {
        b_basic_id: auditList[0]?.b_basic_id,
        company_id: auditList[0]?.company_id,
      },
      fetch_mode: 'all',
      is_query_return: isShowReturn,
    };
    const getBatchList = post('Table/Search/batchList', { ...req, category: 'Batch', tab: 'on_way_fee_batch_detail' });
    const getTotalInfo = post('Table/Search/report', {
      ...req,
      category: 'Report',
      tab: 'tt_round_trip_summary_v1',
    });
    const getFeeList = post('Table/Search/batchFeeApprovalList', {
      ...req,
      category: 'BatchFeeApproval',
      tab: 'b_fee_list_detail_for_batch',
    });
    this.setState({ loading: true });
    Promise.all([getBatchList, getTotalInfo, getFeeList]).then(res => {
      const batchRes = res?.[0]?.res;
      const totalRes = res?.[1]?.res;
      const feeRes = res?.[2]?.res;
      this.setState({ loading: false });
      if (+res[0]?.errno !== 0) {
        return showInfo(ERROR, res[0].errmsg);
      }
      // errno 2034002003 运单没有关联往返
      if (isShowReturn && +res[1]?.errno !== 0 && +res[1]?.errno !== 2034002003) {
        showInfo(ERROR, res[1].errmsg);
      }
      if (+res[2]?.errno !== 0) {
        return showInfo(ERROR, res[2].errmsg);
      }
      // 不选中直接点击按单审核，如果审核状态不是待审核、审核中，或者没有审核权限则过滤
      const statusPass =
        batchRes?.data.find(i => i.on_way_fee_check_st === '待审核' || i.on_way_fee_check_st === '审核中') !==
        undefined;
      const permissionPass =
        feeRes?.data.filter(i => {
          const disableArray = i?.otherProps?.disable;
          const disable = disableArray?.includes('pass') && disableArray?.includes('rebut');
          return !disable;
        }).length > 0;
      const canAudit = statusPass && permissionPass;
      if (dataType === 'list' && !canAudit) {
        this.toNext();
      }
      this.setState(
        {
          batchData: {
            data: batchRes?.data,
            header: batchRes?.header,
            total: batchRes?.total,
          },
          totalData: {
            data: totalRes?.data,
            enumerations: totalRes?.enumerations,
            header: totalRes?.header,
            total: totalRes?.total,
            showText: +res?.[1]?.errno === 2034002003, // errno 2034002003 运单没有关联往返
          },
          feeData: {
            data: feeRes?.data,
            enumerations: feeRes?.enumerations,
            header: feeRes?.header,
            total: feeRes?.total,
          },
          remark: totalRes?.data?.[0]?.round_trip_remark || '',
          canAudit,
        },
        () => {
          this.setState(prevState => ({
            totalData: {
              ...prevState.totalData,
              collapsed: Storage.get('fee_audit_total_collapsed'),
            },
          }));
        },
      );
    });
  };
  onInputChange = (key, val) => {
    this.setState({
      [key]: val,
    });
  };
  // 获取打印菜单
  getPrintMenu = async () => {
    try {
      const req = {
        dynamic: 'Truck/Batch,getReturnTaskPrintButton',
        category: 'BatchFeeApproval',
        tab: 'b_fee_list_detail_for_batch',
        button: 'printer',
        sublist: ['print_preview'],
      };
      const res = await post(DYNAMIC_BUTTON_URL, req);
      if (+res.errno !== 0) {
        return showInfo(ERROR, res?.errmsg);
      }
      const subList = [];
      const resData = res.res || {};
      const dynamicSublist = resData.printer || {};
      const childList = dynamicSublist.children;
      if (typeIs(childList, 'object')) {
        Object.keys(childList).forEach(sublistKey => {
          const dynamicSublistItem = childList[sublistKey];
          subList.push({
            key: sublistKey,
            ...dynamicSublistItem,
            iconType: 'icon-print-out',
          });
        });
        this.setState({
          subList: [...subList],
        });
      }
    } catch (e) {
      console.error(e);
    }
  };
  // 打印列表点击
  sublistClick = selected => {
    if (!selected) {
      if (!this.state.subList.length) {
        showInfo(WARN, '请您在打印设置中设置往返自定义打印模板');
      }
      return;
    }
    const { feeData } = this.state;
    const pageInfo = getPageKey({ controller: 'Schedule', action: 'onWayFeeList' });
    const that = proxyListPage(feeData.data, {
      enums: feeData.enumerations,
      header: feeData.header,
      category: 'BatchFeeApproval',
      tab: 'b_fee_list_detail_for_batch',
    });
    that.getTableInfo = () => pageInfo;
    printFn(that, 'print_return_task', undefined, undefined, selected);
  };
  // 修改关联往返
  returnCheck = val => {
    this.setState({ isShowReturn: val }, () => {
      this.getData();
    });
    // 保存用户关联往返勾选情况
    localStorage.setItem(`is_show_return.${window.user_id}`, val);
  };
  // 运单图片点击
  onImgClick = (index, data) => {
    this.setState({
      showImg: true,
      galleryKey: BATCH_IMAGE_UUID,
      imgList: data,
      imgIndex: index,
    });
  };
  // 折叠
  onToggleCollapsed = (val, type) => {
    const dataKey = `${type}Data`;
    const data = this.state[dataKey] ?? {};
    this.setState({
      [dataKey]: {
        ...data,
        collapsed: val,
      },
    });
    Storage.set('fee_audit_total_collapsed', val);
  };
  // 表格链接点击
  handleLinkClick = (viewRowIndex, column, data, item) => {
    const { feeData } = this.state;
    const that = proxyListPage(feeData.data, {
      enums: feeData.enumerations,
      header: feeData.header,
      category: 'BatchFeeApproval',
      tab: 'b_fee_list_detail_for_batch',
    });
    // 图片
    if (column === 'pic') {
      this.setState(
        {
          showImg: true,
          galleryKey: data?.uuid || uuidv4(),
          imgList: data[column],
          imgIndex: data[column]?.findIndex(i => i.imgKey === item.imgKey),
        },
        () => {
          const { imgList, imgIndex } = this.state;
          const picTag = imgTagMap[imgList?.[imgIndex]?.source ?? 'pic'];
          this.ImageTagRef?.handleChangeValue(picTag);
        },
      );
    }
    // 运单号
    if (column === 'car_batch') {
      batchView(that, viewRowIndex, column, data);
    }
    // 收付款单号
    if (column === 'bill_no') {
      const { otherProps } = (that.getData() || [])[viewRowIndex];
      const { operate = [] } = otherProps || {};
      const isApproval = operate.find(os => os.key === 'audit_log');
      initEmt('batchFeeApprovalBillNo', that, { rowIndex: viewRowIndex, colKey: column, isApproval });
    }
    // 在途费用编号
    if (column === 'on_way_fee_number') {
      batchFeeView(that, viewRowIndex, column, data);
    }
    // 订单编号
    if (column === 'relation_order_num') {
      waybillOrderView(that, viewRowIndex, column, data, item);
    }
    // 上条在途费用编号
    if (column === 'pre_number') {
      batchFeeView(that, viewRowIndex, column, { ...data, id: data.pre_approval_id });
    }
    // 往返编号
    if (column === 'return_num') {
      roundTripDetail(that, viewRowIndex, column, data);
    }
    // 疑似重复报销
    if (column === 'has_susp_dupes') {
      new PopUp(ModalDialog, {
        title: (
          <React.Fragment>
            <i className="fn-icon fn-icon-warn-o" />
            <div>相似费用</div>
          </React.Fragment>
        ),
        content: <SimilarFee id={data?.id} />,
        popName: column,
        isDrag: false,
        isShow: true,
        style: { width: 900 },
      }).show();
    }
  };
  getProxyTableInfo = type => {
    const { batchData, totalData, feeData } = this.state;
    const tableMap = {
      batch: {
        data: batchData.data,
        category: 'Batch',
        tab: 'on_way_fee_batch_detail',
      },
      total: {
        data: totalData.data,
        category: 'Report',
        tab: 'tt_round_trip_summary_v1',
      },
      fee: {
        data: feeData.data,
        category: 'BatchFeeApproval',
        tab: 'b_fee_list_detail_for_batch',
      },
    };
    return tableMap[type];
  };
  getProxyListPage = type => {
    const tableInfo = this.getProxyTableInfo(type);
    if (!tableInfo) return;
    const listPage = proxyListPage(tableInfo.data, { category: tableInfo.category, tab: tableInfo.tab });
    return listPage;
  };
  // 列表设置
  setHeader = type => {
    const trackMap = {
      batch: ['审核在途费用', '在途费用-费用审核-按圈单审核侧拉运单列表设置'],
      total: ['审核在途费用', '在途费用-费用审核-按圈单审核侧拉圈合计列表设置'],
      fee: ['审核在途费用', '在途费用-费用审核-按圈单审核侧拉费用明细列表设置'],
    };
    dataAnalyticTrack(trackMap[type]);
    const listPage = this.getProxyListPage(type);
    const ext = {
      callback: this.getData,
    };
    setHeader(listPage, ext);
  };
  // 保存样式
  saveTableStyle = type => {
    return (body, isShowMessage = true) => {
      const tableInfo = this.getProxyTableInfo(type);
      if (!tableInfo) return;
      const listPage = this.getProxyListPage(type);
      setTable(tableInfo.category, tableInfo.tab, body, listPage, isShowMessage)();
    };
  };
  // 调整列宽后的回调
  hanldeAutoWidth = type => {
    return (columnKey, newColumnWidth) => {
      const dataKey = `${type}Data`;
      const data = this.state[dataKey] ?? {};
      if (!data?.header) return;
      const { header } = data;
      if (!header[columnKey]) return;
      const newHeader = _.cloneDeep(header);
      newHeader[columnKey].width = newColumnWidth;
      this.setState({
        [dataKey]: {
          ...data,
          header: newHeader,
        },
      });
    };
  };
  // 表格变化
  handleChange = (rowIndex, columnKey, value) => {
    const { feeData } = this.state;
    const newFeeData = _.cloneDeep(feeData);
    const excludeKey = ['pass', 'rebut'].filter(i => i !== columnKey)[0];
    newFeeData.data[rowIndex][columnKey] = value;
    // 通过与驳回互斥
    if ((columnKey === 'pass' || columnKey === 'rebut') && value) {
      newFeeData.data[rowIndex][excludeKey] = false;
    }
    this.setState({
      feeData: newFeeData,
    });
  };
  // 表头checkbox
  handleHeaderCheck = (e, columnKey, columnProps, value) => {
    const { feeData } = this.state;
    const newFeeData = _.cloneDeep(feeData);
    const excludeKey = ['pass', 'rebut'].filter(i => i !== columnKey)[0];
    newFeeData.header[columnKey].checked = value;
    if (value) {
      newFeeData.header[excludeKey].checked = false;
    }
    newFeeData.data.forEach((item, index) => {
      if (item?.otherProps?.disable?.includes(columnKey)) return;
      newFeeData.data[index][columnKey] = value;
      // 通过与驳回互斥
      if (value) {
        newFeeData.data[index][excludeKey] = false;
      }
    });
    this.setState({
      feeData: newFeeData,
    });
  };
  // 表格操作列
  handleOperateClick(rowIndex, key, columnKey, data) {
    dataAnalyticTrack(['审核在途费用', '在途费用-费用审核-按圈单审核侧拉-审批跟踪']);
    // 审批跟踪
    if (key === 'batch_fee_audit_log') {
      auditLog(data, 'fee_report_apply');
    }
  }
  // 下一单
  toNext = () => {
    const { auditList, batchData } = this.state;
    // 审核列表移除当前数据
    batchData?.data?.forEach(i => {
      auditList?.splice(
        auditList?.findIndex(j => i.b_basic_id === j.b_basic_id),
        1,
      );
    });
    this.datContext = {
      originAuditList: this.props.auditList,
      batchData: (this.datContext?.batchData ?? [])?.concat?.(batchData),
      localAuditList: (this.datContext?.localAuditList ?? [])?.concat?.(_.cloneDeep(auditList)),
    };
    if (!auditList.length) {
      showInfo(ERROR, '已无待审核运单！');
      sentryReporter?.setLevel('error').setExtras(this.datContext).rdcReport({
        module: 'TaskInTransitFeeAuditByBatch',
        description: 'This is a annoying report.',
      });
      setTimeout(() => {
        this.close();
      }, 1000);
      return;
    }
    this.setState(
      {
        auditList: _.isArray(auditList) ? [...auditList] : auditList,
      },
      () => {
        this.getData();
      },
    );
  };
  // 更新圈备注
  updateRemark = throttle(async () => {
    const { batchData, remark } = this.state;
    const req = {
      return_num: batchData?.data?.[0]?.return_num,
      remark,
    };
    const res = await postJAVA('/cmm-batch/roundTrip/info/save', req);
    if (+res.errno !== 0) {
      return showInfo(ERROR, res.errmsg);
    }
  }, 2000);
  onSubmit = async () => {
    dataAnalyticTrack(['审核在途费用', '在途费用-费用审核-按圈单审核侧拉-提交审批']);
    const { batchData, feeData, auditList } = this.state;
    const { i18n } = this.props;
    const batchDict = i18n.get('batch', '批次');
    const data = feeData?.data?.filter(item => {
      const disableArray = item?.otherProps?.disable;
      const disable = disableArray?.includes('pass') && disableArray?.includes('rebut');
      return !disable && (item.pass === true || item.rebut === true);
    });

    if (!data.length) {
      return showInfo(ERROR, '无数据需要审批，请确认');
    }

    // 如果有关联往返运单的费用审核，设置来源为4
    let checkSource = 3;
    const batchArray = data.map(i => i.b_link_id);
    if ([...new Set(batchArray)].length > 1 || batchArray[0] !== batchData?.data?.[0]?.b_link_id) {
      checkSource = 4;
    }
    // 是否是最后一个审批节点
    let isLastAuditorIds = [];
    let sucIds = [];
    const req = {
      data: data
        .filter(i => i.pass)
        .map(i => ({
          apply_id: i.id,
          operation: 'fee_report_apply',
        })),
    };
    post('/Basic/Approval/isLastAuditor', req).then(res => {
      if (res?.res?.success?.length) {
        isLastAuditorIds = res?.res?.success?.map(i => i.apply_id);
      }
    });
    // 标记是否有审批
    this.setState({
      update: true,
      loadingBtn: true,
    });
    const header = {
      car_batch: { title: `${batchDict}号`, display: 'show', width: 150, dispay: 'show' },
      fee_id: { title: '费用项', display: 'show', dispay: 'show', refEnum: 'fee_id_all' },
      amount: { title: '金额', display: 'show', dispay: 'show' },
    };
    new PopUp(BatchOp, {
      title: '批量审核',
      tips: '审核',
      header,
      data,
      enumerations: feeData.enumerations,
      requestUrl: 'Basic/Approval/audit',
      escBind: false,
      getPara: dataList => ({
        audit: dataList[0].pass ? 1 : 4,
        opinion: dataList[0].audit_remark,
        data: [
          {
            number: `${dataList[0].car_batch}，${
              feeData?.enumerations?.fee_id_all?.find(i => +i.key === +dataList[0]?.fee_id)?.name
            }`,
            apply_id: dataList[0].id,
            operation: 'fee_report_apply',
          },
        ],
        check_source: checkSource, // 在途费用审核埋点
      }),
      paceCallback: callbackRes => {
        sucIds.push(callbackRes?.res?.success?.[0]);
      },
      footerRender: emit => {
        const { isFinish, ready } = emit.state;
        const btnText = isFinish ? '关闭' : '取消';
        const cancelType = ready ? 'primary' : 'default';
        sucIds = sucIds.filter(i => isLastAuditorIds?.indexOf(i) > -1);
        const showPaymentBtn = isFinish && sucIds.length > 0 && hasPermission('reviewPaymentOnwayV1');
        return (
          <div>
            {auditList.length > 1 && isFinish && (
              <Button
                type="primary"
                key="confirm"
                onClick={() => {
                  emit.handleButtonClick();
                  this.toNext();
                }}
              >
                下一单
              </Button>
            )}
            {showPaymentBtn && (
              <Button
                type="primary"
                key="confirm"
                onClick={() => {
                  this.createPayment(sucIds);
                  emit.handleButtonClick();
                  this.getData();
                }}
              >
                “审核通过”数据创建司机报销单
              </Button>
            )}
            <Button
              type={cancelType}
              key="cancel"
              onClick={() => {
                emit.handleButtonClick();
                this.close();
              }}
            >
              {btnText}
            </Button>
          </div>
        );
      },
    }).show();

    this.setState({
      loadingBtn: false,
    });
  };
  // 创建报销单
  createPayment = ids => {
    const { pageInfo } = this.props;
    batchPaymentApply(pageInfo, 'batch_payment_apply_v1', {
      ids,
      isTask: false,
    });
  };
  close = () => {
    this.props.close();
    if (this.state.update) {
      this.props?.onSuccess();
    }
  };
  // 运单汇总信息
  renderBatchHeader = () => {
    const { isShowReturn, batchData } = this.state;
    return (
      <div className={cls('card-box-header')}>
        {!this.props.justShowBatchDetail && (
          <CheckBox label="同时审往返运单" direction="right" checked={isShowReturn} onChange={this.returnCheck} />
        )}
        <div className="total-info">
          <div className="total-item">
            <span className="item-label">在途费用合计:</span>
            <span className="text-error">{batchData?.total?.onway_fee_total}</span>
          </div>
          <Icon iconType="icon-help" tips="已分摊到运单上且费用审核状态为待审核/已审核/审核中/无需审核费用项的合计值" />
        </div>
        <Tips position="top" title="列表设置">
          <div className="fn-btn-icon-setting" onClick={() => this.setHeader('batch')}>
            <Icon iconType="icon-setting" />
          </div>
        </Tips>
      </div>
    );
  };
  // 回单照片
  renderImg(data = []) {
    if (!data.length) return;
    return data.map((item, index) => (
      <span className="link link-img" key={item.imgKey} onClick={() => this.onImgClick(index, data)}>
        [{index + 1}]
      </span>
    ));
  }
  // 运单详情
  renderBatchContent = () => {
    const { batchData, showImg } = this.state;
    return batchData.data?.map((item, index) => {
      const formItems = Object.keys(batchData.header)
        .filter(i => batchData?.header?.[i]?.display === 'show')
        .map(key => {
          if (batchData?.header?.[key]?.columnType === 'pic') {
            return (
              <div className="form-item" key={key}>
                <div className="item-label">{batchData.header?.[key]?.title}</div>
                <div className="item-content">{this.renderImg(batchData.data?.[index]?.[key])}</div>
              </div>
            );
          } else {
            return (
              <div className="form-item" key={key}>
                <div className="item-label">{batchData.header?.[key]?.title}</div>
                <div className="item-content">{batchData.data?.[index]?.[key]}</div>
              </div>
            );
          }
        });
      return (
        <div className={`form ${!showImg && 'form-m'}`} key={item.b_basic_id}>
          {formItems}
        </div>
      );
    });
  };
  // 圈信息汇总标题栏
  renderTotalHeader() {
    return (
      <Tips position="top" title="列表设置">
        <div className="fn-btn-icon-setting" onClick={() => this.setHeader('total')}>
          <Icon iconType="icon-setting" />
        </div>
      </Tips>
    );
  }
  // 圈信息汇总
  renderTotalInfo() {
    const { totalData, remark } = this.state;
    const { enumerations, data, header } = totalData;
    const showHeader = _.pick(
      header,
      Object.keys(header).filter(i => header[i].display === 'show'),
    );
    return (
      <>
        <div className="table-wrapper">
          <Table
            rowHeight={32}
            headerHeight={36}
            enumerations={enumerations}
            header={showHeader}
            data={data}
            isShowSort={false}
            isRowSelect={false}
            canFreeClumn
            isShowTotalRow={false}
            isShowFilterRow={false}
            isShowOperation
            canSaveTableStyle
            hanldeAutoWidth={this.hanldeAutoWidth('total')}
            handleSaveTableStyle={this.saveTableStyle('total')}
            handleChange={this.handleChange}
            handleHeaderCheck={this.handleHeaderCheck}
            handleOperateClick={this.handleOperateClick}
            handleLinkClick={this.handleLinkClick}
            showScrollbarY
          />
        </div>
        <PureTextarea
          className="remark"
          value={remark}
          maxLength="500"
          placeholder="请填写圈备注..."
          onBlur={this.updateRemark}
          onChange={e => this.onInputChange('remark', e.target.value)}
        />
      </>
    );
  }
  // 费用汇总信息
  renderFeeHeader = () => {
    const { feeData, subList, batchData } = this.state;
    const feeType = [
      {
        name: ft('油费'),
        key: 'oil_fee',
      },
      {
        name: ft('尿素费'),
        key: 'urea_fee',
      },
      {
        name: ft('路桥费'),
        key: 'road_fee',
      },
      {
        name: ft('其他费'),
        key: 'other_fee',
      },
    ];
    const totalItems = feeType.map(item => (
      <div className="total-item">
        <span className="item-label">{item.name}:</span>
        合计 <span className="amount text-default">{feeData?.total?.[item.key]?.total}</span>
        待审 <span className="amount text-error">{feeData?.total?.[item.key]?.uncheck_fee}</span>
        已审 <span className="amount text-check">{feeData?.total?.[item.key]?.check_fee}</span>
      </div>
    ));
    const tips = (
      <div>
        <div>合计：审核状态为待审核/审核中/已审核/无需审核的费用合计</div>
        <div>待审：审核状态为待审核/审核中的费用合计</div>
        <div>已审：审核状态为已审核/无需审核的费用合计</div>
      </div>
    );
    return (
      <div className={cls('card-box-header')}>
        <div className="total-info">
          {totalItems}
          <Icon iconType="icon-help" tips={tips} />
        </div>
        {batchData?.data?.[0]?.return_num && window.permission?.includes('printBatchOnwayFeeForBatchV1') && (
          <MenuBtn
            subList={subList}
            buttonProps={{
              iconType: 'icon-print-out',
              children: '打印',
            }}
            classname="btn-print"
            showKey="title"
            tableHeader={{ title: '' }}
            display
            style={{ width: 165 }}
            onClick={this.sublistClick}
          />
        )}
        <Tips position="top" title="列表设置">
          <div className="fn-btn-icon-setting" onClick={() => this.setHeader('fee')}>
            <Icon iconType="icon-setting" />
          </div>
        </Tips>
      </div>
    );
  };
  // 费用列表
  renderFeeContent() {
    const { feeData } = this.state;
    const { enumerations, data, header } = feeData;
    const showHeader = _.pick(
      header,
      Object.keys(header).filter(i => header[i].display === 'show'),
    );
    return (
      <div className="table-wrapper">
        <Table
          rowHeight={32}
          headerHeight={36}
          enumerations={enumerations}
          header={showHeader}
          data={data}
          isShowSort={false}
          isRowSelect={false}
          canFreeClumn
          isShowTotalRow={false}
          isShowFilterRow={false}
          isShowOperation
          canSaveTableStyle
          hanldeAutoWidth={this.hanldeAutoWidth('fee')}
          handleSaveTableStyle={this.saveTableStyle('fee')}
          handleChange={this.handleChange}
          handleHeaderCheck={this.handleHeaderCheck}
          handleOperateClick={this.handleOperateClick}
          handleLinkClick={this.handleLinkClick}
          showScrollbarY
        />
      </div>
    );
  }
  renderContent() {
    const { showImg, imgIndex, imgList, galleryKey, totalData, isShowReturn } = this.state;
    const { collapsed, showText } = totalData;
    const galleryItems = getSpecialGalleryGroup(imgList);
    const picTag = imgTagMap[imgList?.[imgIndex]?.source ?? 'pic'];
    return (
      <div className={cls('container')}>
        <div className={cls('top-container')}>
          <CardBox
            title="运单信息"
            className={`${cls('top-left')} ${showImg && 'w-70'}`}
            header={this.renderBatchHeader()}
          >
            {this.renderBatchContent()}
          </CardBox>

          <div
            className={`${cls('top-middle')} ${!showImg && 'hide'}`}
            onClick={() => this.setState({ showImg: false })}
          >
            <i className="fn-icon fn-icon-extend-right" />
          </div>
          <div className={`${cls('top-right')} ${!showImg && 'hide'}`}>
            <div className="gallery-container">
              {galleryItems && galleryItems.length ? (
                <>
                  <ImageTag tag={picTag} ref={ref => (this.ImageTagRef = ref)} />
                  <Gallery
                    items={galleryItems}
                    showThumbnails={false}
                    startIndex={imgIndex}
                    fitHeight={400 - 37} // 37 为tag的高度
                    customDownLoad={info => {
                      customDownload(info, galleryItems);
                    }}
                    key={galleryKey}
                    onSlide={(i, list) => {
                      const { source = 'pic' } = list?.[i] || imgList?.[i] || {};
                      const tag = imgTagMap[source];
                      this.ImageTagRef?.handleChangeValue(tag);
                    }}
                  />
                </>
              ) : null}
            </div>
          </div>
        </div>
        {isShowReturn && (
          <CardBox
            title="圈汇总信息"
            defaultCollapsed={collapsed}
            onToggleCollapsed={val => this.onToggleCollapsed(val, 'total')}
            header={this.renderTotalHeader()}
            className={cls('middle')}
          >
            {showText ? (
              <div className="tips">该运单没有关联往返单形成圈，暂时没有圈的汇总信息</div>
            ) : (
              this.renderTotalInfo()
            )}
          </CardBox>
        )}
        {!this.props.justShowBatchDetail && (
          <CardBox title="费用明细" header={this.renderFeeHeader()} className={cls('bottom-container')}>
            {this.renderFeeContent()}
          </CardBox>
        )}
      </div>
    );
  }
  renderFooter = () => {
    const { auditList, canAudit, loadingBtn } = this.state;
    return (
      <div className="slide__drager__footer__content">
        {auditList.length > 1 && <Button onClick={this.toNext}>跳过，下一单</Button>}
        {canAudit && (
          <Button onClick={this.onSubmit} type="primary" loading={loadingBtn}>
            提交审批
          </Button>
        )}
        {auditList.length === 1 && !canAudit && (
          <Button onClick={this.close} type="primary">
            关闭
          </Button>
        )}
      </div>
    );
  };
  render() {
    return (
      <div className={cls('')}>
        <Load spinning={this.state.loading}>
          <SlideDrager
            isShow
            isBiggest
            slideWidth={1000}
            // eslint-disable-next-line no-return-assign
            ref={ref => (this._slideDrager = ref)}
            close={this.close}
            HeaderTitle={this.props.title ? this.props.title : '审核在途费用'}
            contentDiv={this.renderContent()}
            footerDiv={this.renderFooter()}
          />
        </Load>
      </div>
    );
  }
}
export default withPopUpI18n(TaskInTransitFeeAuditByBatch);
