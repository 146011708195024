import extraGlobalState from '@/utils/extraGlobalState';
import { PureInput, SelectDrop, TextArea, UploadImgText } from 'components';
import React from 'react';
import Card from '../card';
import InfoItem from '../infoItem';

const handleType = {
  deal: '处理',
  audit: '审核',
  reply: '回复',
};
class handleInfo extends React.Component {
  constructor(props) {
    super(props);
    this.onChangeCache = {};
  }
  onChange = key => {
    if (!this.onChangeCache[key]) {
      this.onChangeCache[key] = e => {
        const val = e && e.target ? e.target.value : e;
        this.props.set('handleInfo', key, val);
      };
    }
    return this.onChangeCache[key];
  };
  render() {
    const { type, data, enums, imgRefGetter, rmkRefGetter, set } = this.props;
    const typeText = handleType[type];
    const useNewWayEnabled = extraGlobalState.getState('isNewWayEnabled');

    return (
      <Card title={`异常${typeText}`}>
        <InfoItem label={`${typeText}时间`}>
          <PureInput value={data.time} disabled />
        </InfoItem>
        <InfoItem label={`${typeText}网点`}>
          <SelectDrop
            defaultSelected={data.company_id}
            disabled
            uniqueKey="id"
            showKey="short_name"
            data={enums.company_id}
          />
        </InfoItem>
        <InfoItem label={`${typeText}人`}>
          <SelectDrop defaultSelected={data.user_id} disabled uniqueKey="id" showKey="name" data={enums.user_id} />
        </InfoItem>
        <InfoItem className="rmk" required label={`${typeText}意见`}>
          <TextArea
            defaultValue={data.rmk}
            ref={rmkRefGetter}
            max-length="500"
            onChange={this.onChange('rmk')}
            placeholder="最多输入500个汉字"
            required
          />
        </InfoItem>
        <InfoItem key="imgs" className="imgs-tip">
          <span>注意：最多上传10个图片，每个图片不能大于3M</span>
        </InfoItem>
        <UploadImgText
          cameraShow
          type="abnormal"
          ref={imgRefGetter}
          isOss
          isAsyn={useNewWayEnabled}
          list={data.add_imgs}
          maxNum={10}
        />
      </Card>
    );
  }
}
export default handleInfo;
