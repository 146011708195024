import classnames from 'classnames';
import { Button, ModalDialog, PopUp, UploadImgText } from 'components';
import BatchOp from 'components/commoncomponents/BatchOp';
import { CHECK, ERROR, INFO, NETERR, WARN } from 'constants';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { fetchJAVA, showInfo } from 'utils';
import { prefixCls } from '../../utilcomponents/dialog/ShowInfo/index.scss';
import { prefixCl } from './commentDialog.scss';
import Start from './start';

export default class CommentDialog extends PureComponent {
  // static displayName = 'CommentDialog'

  constructor(prop) {
    super(prop);
    this.simpleModeBtns = {
      confirm: { name: '确定', btnType: 'primary', cb: this.onConfirm },
      cancel: { name: '取消', btnType: 'default', cb: this.onCancel },
    };
    // button点击的回调
    this.btnsHandles = {};
    this.state = {
      isShow: prop.isShow,
      buttons: this.setButtonStatus(prop),
      imageValue: [],
      remark: '',
      driverCommentNO: '',
    };
  }

  static defaultProps = {
    classname: '',
    type: CHECK,
    isShow: true,
    autoDestroy: true,
    title: '提示',
    buttons: [],
    closable: true,
    maskClosable: true,
    showIcon: true,
    simpleText: {}, // 自定义按钮文字
    isModal: false,
    autoHide: false,
    autoHideTime: 2000,
  };
  static propTypes = {
    classname: PropTypes.string,
    // 弹框中icon提示类型
    type: PropTypes.oneOf([WARN, ERROR, INFO, CHECK, NETERR]),
    // 是否显示icon
    showIcon: PropTypes.bool,
    // 是否默认显示
    isShow: PropTypes.bool,
    // 弹框提示正文
    content: PropTypes.any,
    // 弹框提示正文下面的说明
    contentNotice: PropTypes.any,
    // 是否自动销毁（for popup）
    autoDestroy: PropTypes.bool,
    // 弹框标题
    title: PropTypes.string,
    // 传入 confirm cancel 对应的callback
    simpleMode: PropTypes.object,
    // 默认文本
    simpleText: PropTypes.object,
    // 直接从外部制定buttons, 每个buttons需要有key
    buttons: PropTypes.array,
    // 是否有右上角查号
    closable: PropTypes.bool,
    // 是否点击遮罩关闭
    maskClosable: PropTypes.bool,
    // 关闭回调（for popup）
    close: PropTypes.func,
    closableCallback: PropTypes.func,
    isModal: PropTypes.bool,
    // 自动消失 自动销毁为true 则销毁
    autoHide: PropTypes.bool,
    // 自动消失时间
    autoHideTime: PropTypes.number,
    // 弹框中icon提示类型
    iconType: PropTypes.array,
    data: PropTypes.any,
    subjective: PropTypes.bool,
    id: PropTypes.string,
    appraise_id: PropTypes.string,
    appraise_type: PropTypes.string,
    needData: PropTypes.array,
    remark: PropTypes.string,
    dialogTitle: PropTypes.string,
    isDetail: PropTypes.bool,
    batch: PropTypes.bool, // 是否批量评价
    tips: PropTypes.string, // 是否批量评价
  };

  componentDidMount() {
    const { autoHide, autoHideTime } = this.props;
    if (this.state.isShow && autoHide) {
      setTimeout(() => this.handleHide(), autoHideTime);
    }
    if (this.props.data.imgs) {
      // 有imgs
      this.state.isShow = true;
      if (this.props.data.imgs[0] === null) {
        this.state.isShow = false;
      }
    } else {
      this.state.isShow = false;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isShow) {
      this.setState({
        isShow: nextProps.isShow,
      });
    }
  }

  // 兼容不同的参数生成最后弹框上的button
  setButtonStatus = prop => {
    let buttons = prop.buttons || [];
    // 从外部以 simpleMode 的形式传入 button 回调
    if (this.props.simpleMode) {
      buttons = [];
      Object.entries(this.props.simpleMode).map(([key, item]) => {
        item &&
          buttons.push({
            key,
            name: this.props.simpleText[key] || this.simpleModeBtns[key].name,
            btnType: this.props.simpleBtnType || this.simpleModeBtns[key].btnType,
            cb: item,
          });
        return true;
      });
    }
    // 不传button的情况下, 用默认值
    if (!Array.isArray(buttons) || buttons.length === 0) {
      buttons = [
        { key: 'confirm', name: '确定', btnType: 'primary', cb: this.onConfirm },
        { key: 'cancel', name: '取消', btnType: 'default', cb: this.onCancel },
      ];
    }
    // 将button的回调方法集体存储在 btnHandles中
    buttons.forEach((item, index) => {
      // 兼容以往不传key值的button
      buttons[index].key = buttons[index].key || buttons[index].name;
      this.btnsHandles[buttons[index].key] = item.cb && typeof item.cb === 'function' && item.cb;
    });
    return buttons;
  };
  // 暂未使用
  onConfirm = () => {};
  onCancel = () => {};
  /*
   * 修改button的状态
   * btnKey: 要修改的btnKey
   * flag: 是否为loading状态
   */
  changeBtnLoadingStatus = (btnKey, flag) => {
    const { buttons } = this.state;
    const newButtons = [];
    buttons.forEach(iitem => {
      const item = iitem;
      if (item.key === btnKey) {
        item.loading = flag;
      } else {
        item.disabled = flag;
      }
      newButtons.push(item);
    });
    this.setState({ buttons: newButtons });
  };
  handleHide = () => {
    this.infoDialog && this.infoDialog.handleHide();
  };
  handleShow = () => {
    this.infoDialog && this.infoDialog.handleShow();
  };
  // button的点击操作
  handleButtonClick = (key, ...arg) => {
    const that = this;
    // pop关闭时判断是否有修改 提示弹框关闭时 事件会冒泡到已销毁的组件
    arg[0] && arg[0].stopPropagation && arg[0].stopPropagation();
    this.btnsHandles[key](this, key, ...arg);
    const req = {
      appraise_type: this.props.appraise_type,
      from_page: 'task',
      objective_appraise: this.props.subjective
        ? {
            customer: {
              appraise_detail: this._start2.getData(),
            },
            driver: {
              appraise_detail: this._start4.getData(),
            },
            carrier: {
              appraise_detail: this._start3.getData(),
            },
            remark: this.state.remark,
            imgs: this.state.imageValue,
          }
        : undefined,
      subjective_appraise:
        this.props.subjective === false
          ? {
              appraise_detail: this._start1.getData(),
            }
          : undefined,
      appraise_id: this.props.appraise_id,
    };
    if (this.props.subjective === false) {
      const driverCommentNO = this._start1.getData().find(item => item.grade === 0);
      this.state.driverCommentNO = driverCommentNO;
    }
    if (this.props.subjective) {
      const driverCommentNO2 = this._start2.getData().find(item => item.grade === 0);
      this.state.driverCommentNO = driverCommentNO2;
      const driverCommentNO3 = this._start3.getData().find(item => item.grade === 0);
      this.state.driverCommentNO = driverCommentNO3;
      const driverCommentNO4 = this._start4.getData().find(item => item.grade === 0);
      this.state.driverCommentNO = driverCommentNO4;
    }

    console.log(key === 'confirm' && this.state.driverCommentNO);
    if (key === 'confirm' && this.state.driverCommentNO) {
      showInfo(ERROR, `${this.state.driverCommentNO.item}未评价`);
      return;
    }
    if (key === 'confirm' && !this.props.batch) {
      fetchJAVA('/cmm-batch/appraise/submit', {
        method: 'POST',
        body: {
          req: { ...req, id: this.props.id },
        },
      }).then(res => {
        if (res.errno === 0) {
          showInfo(CHECK, '评价成功');
          this.infoDialog && this.infoDialog.handleHide();
        } else {
          showInfo(ERROR, res.errmsg);
        }
      });
    }
    if (key === 'confirm' && this.props.batch) {
      const header = {
        car_batch: { title: this.props.dialogTitle, display: 'show' },
        status: { title: '操作状态', display: 'show' },
      };
      new PopUp(BatchOp, {
        title: this.props.title,
        header,
        data: this.props.needData,
        tips: this.props.tips,
        requestUrl: '/cmm-batch/appraise/submit',
        isJAVA: true,
        getPara: dataList => {
          const reqJson = { ...req, id: dataList[0].id };
          return reqJson;
        },
        finishCallback: () => {
          this.props.finishCallback();
          this.infoDialog && this.infoDialog.handleHide();
        },
        escBind: false,
      }).show();
    }
    if (key === 'confirm' && !this.props.batch) {
      fetchJAVA('/cmm-batch/appraise/submit', {
        method: 'POST',
        body: {
          req: { ...req, id: this.props.id },
        },
      }).then(res => {
        if (res.errno === 0) {
          showInfo(CHECK, '评价成功');
          this.props.finishCallback();
          this.infoDialog && this.infoDialog.handleHide();
        } else {
          showInfo(ERROR, res.errmsg);
        }
      });
    }
    if (key === 'cancel') {
      this.infoDialog && this.infoDialog.handleHide();
    }
  };
  // 生成底部button
  renderBottom = () => (
    <div>
      {this.props.isDetail ? null : (
        <div>
          {this.state.buttons.map(item => (
            <Button
              type={item.btnType}
              key={item.name}
              loading={item.loading}
              disabled={item.disabled}
              onClick={(...arg) => this.handleButtonClick(item.key, ...arg)}
            >
              {item.name}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
  handleUPChange = value => {
    this.state.imageValue.push(value);
  };
  contentNotice = () => (
    <div className={prefixCl}>
      {this.props.subjective === false ? (
        <div className="queryer-content">
          <Start data={this.props.data.appraise_detail} ref={r => (this._start1 = r)} />
        </div>
      ) : (
        ''
      )}
      {this.props.subjective ? (
        <React.Fragment>
          <div className="queryer-content">
            <p>{this.props.data.customer && this.props.data.customer.appraise_detail.length !== 0 ? '评价客户' : ''}</p>
            <Start
              data={!this.props.data.customer.appraise_detail === null ? [] : this.props.data.customer.appraise_detail}
              ref={r => (this._start2 = r)}
            />
          </div>
          <div className="queryer-content">
            <p>{this.props.data.carrier && this.props.data.carrier.appraise_detail.length !== 0 ? '评价承运商' : ''}</p>
            <Start
              data={!this.props.data.carrier.appraise_detail === null ? [] : this.props.data.carrier.appraise_detail}
              ref={r => (this._start3 = r)}
            />
          </div>
          <div className="queryer-content">
            <p>
              {this.props.data.driver &&
              this.props.data.driver.appraise_detail !== null &&
              this.props.data.driver.appraise_detail.length !== 0
                ? '评价司机'
                : ''}
            </p>
            <Start
              data={this.props.data.driver.appraise_detail === null ? [] : this.props.data.driver.appraise_detail}
              ref={r => (this._start4 = r)}
            />
          </div>
        </React.Fragment>
      ) : (
        ''
      )}
      {this.props.subjective ? (
        <div>
          <label>备注</label>
          <textarea
            className="fn-textarea"
            style={{ width: 486, height: 88 }}
            maxLength="100"
            value={this.props.remark}
            disabled={this.props.isDetail}
            onChange={e => {
              this.setState({ remark: e.target.value });
            }}
          />
        </div>
      ) : (
        ''
      )}
      {this.props.subjective ? (
        <UploadImgText
          labelText="图片上传"
          tips="注意：最多上传10张， 每个图片不能大于3M"
          maxNum={10}
          isAsyn
          isOss
          photoProps={this.props.data.imgs}
          asynReq={{ type: 'appraise' }}
          onChange={this.handleUPChange}
          disabled={this.props.isDetail}
        />
      ) : (
        ''
      )}
    </div>
  );
  render() {
    const { isShow, type, classname, content, title, autoDestroy, closable, maskClosable, close } = this.props;
    const classes = classnames({
      [prefixCls]: true,
      [classname]: classname,
      net_err: type === NETERR,
    });
    return (
      <ModalDialog
        isModal={this.props.isModal}
        classname={classes}
        ref={dialog => (this.infoDialog = dialog)}
        title={title}
        content={this.contentNotice()}
        bottom={type === NETERR ? null : this.renderBottom()}
        autoDestroy={autoDestroy}
        isShow={isShow}
        closable={closable}
        close={close}
        closableCallback={() => (this.props.closableCallback ? this.props.closableCallback(this) : this.handleHide())}
        maskClosable={maskClosable}
      />
    );
  }
}
