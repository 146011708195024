import { FLOAT_2, FLOAT_P_2, STATE_CLS_MAP } from 'constants';
import React from 'react';
import { ft } from 'utils';
import { driverStatusHeader } from 'utils/sug/driver';
import { multiColETCCardHeader } from 'utils/sug/etc';
import { multiColOilCardHeader } from 'utils/sug/oilCard';
import { multiColOrgHeader } from 'utils/sug/org';
import { multiColProviderHeader } from 'utils/sug/serviceProvider';
import { truckDriverHeader } from 'utils/sug/truck';

export default i18n => {
  const taskDict = i18n.get('batch', '任务');
  const batchHeader = [
    { title: `${taskDict}号`, key: 'car_batch' },
    { title: '所属组织', key: 'company_name' },
    { title: '车线名称', key: 'line_name' },
    {
      key: 'batch_st_display',
      title: `${taskDict}状态`,
      render(item) {
        const state = STATE_CLS_MAP.batch.批次状态;
        return <span className={state?.[item.batch_st_display]}>{item.batch_st_display}</span>;
      },
    },
    { title: '车牌号', key: 'b_tr_num' },
    { title: '主驾司机', key: 'b_dr_name' },
    { title: '始发发车时间', key: 'head_truck_t' },
    { title: '终点到达时间', key: 'end_arr_t' },
    { title: '创建时间', key: 'create_time' },
  ];
  const inTransitOilFeeFields = {
    company_id: {
      type: 'Select',
      label: '所属组织',
      filter: 'orgSug',
      header: multiColOrgHeader,
      format: 'short_name',
      compare: 'id',
    },
    fee_id: {
      type: 'Select',
      label: '费用名称',
      header: ['name'],
      format: 'name',
      compare: 'key',
    },
    batch: {
      type: 'Select',
      label: taskDict,
      filter: 'batchSug',
      header: batchHeader,
      format: 'car_batch',
      compare: 'id',
    },
    tr_id: {
      type: 'Select',
      label: '车牌',
      filter: 'truckSug',
      header: truckDriverHeader,
      format: 'tr_num',
      compare: 'id',
    },
    dr_id: {
      type: 'Select',
      label: '司机',
      filter: 'driverSug',
      header: driverStatusHeader,
      format: 'name',
      compare: 'id',
    },
    happen_time: {
      type: 'Time',
      label: '费用发生时间',
    },
    km_num_entrance: {
      type: 'Input',
      label: '当前里程',
      pattern: FLOAT_P_2,
    },
    site_exit: {
      type: 'DataList',
      label: '加油站点',
      filter: 'serviceProviderSug',
      header: multiColProviderHeader,
      map: 'name',
    },
    goods_price: {
      type: 'Select',
      label: '商品名称',
      compare: 'goods_name',
      icon: 'icon-help',
      tip: (
        <div>
          <div>如果您跟加油站有协议约定的油品结算价格，请</div>
          <div>在【车务/后服中心】-【服务商价格管理】维护</div>
          <div>加油站点的商品和价格，维护后您便能选择商品。</div>
          <div>填写【加油量】后，【油价】和【金额】自动匹</div>
          <div>配生成。如您无上述场景，则无需关注该字段。</div>
        </div>
      ),
    },
    quantity: {
      type: 'Input',
      label: '加油量(升)',
      pattern: FLOAT_2,
      icon: 'icon-help',
      tip: '当前站点已登记价格，您只需填写加油量',
      computerIcon: 'icon-calculate',
    },
    amount: {
      type: 'Input',
      label: '金额',
      pattern: FLOAT_2,
      computerIcon: 'icon-calculate',
    },
    unit_price: {
      type: 'Input',
      label: '油价',
      pattern: FLOAT_2,
      icon: 'icon-help',
      tip: '油价根据【服务商价格管理】匹配生成',
      computerIcon: 'icon-calculate',
    },
    tank_full: {
      type: 'Radio',
      label: '是否加满',
    },
    income_expend_way: {
      type: 'Select',
      label: '支付方式',
    },
    card_num: {
      type: 'Select',
      label: '油卡号',
      filter: 'oilCardSug',
      header: [
        ...multiColOilCardHeader,
        { key: 'driver_name', title: '司机姓名' },
        { key: 'driver_tel', title: '司机手机号' },
        { key: 'truck_num', title: '车牌号' },
      ],
      format: val => (val[0] && val[0].card_num) || val[0],
      campare: 'card_num',
    },
    custom_field_1: {
      type: 'Input',
      label: ft('油费自定义字段1'),
    },
    custom_field_2: {
      type: 'Input',
      label: ft('油费自定义字段2'),
    },
    custom_field_3: {
      type: 'Input',
      label: ft('油费自定义字段3'),
    },
    payee: {
      type: 'Select',
      label: ft('收款人'),
      filter: 'truckPayeeSug',
      header: [
        { key: 'tr_payee_name', title: '收款人' },
        { key: 'tr_payee_open_bank', title: '开户行' },
        { key: 'tr_payee_bank_card_num', title: '银行卡' },
      ],
      compare: 'id',
      format: 'tr_payee_name',
    },
    remark: {
      type: 'TextArea',
      label: '备注',
      className: 'remark x-100',
    },
    pic: {
      type: 'UploadImgText',
      label: '图片',
      className: 'x-100',
    },
    payment_pics: {
      type: 'UploadImgText',
      label: '付款照片',
      className: 'x-100',
    },
  };

  const inTransitUreaFeeFields = {
    company_id: {
      type: 'Select',
      label: '所属组织',
      filter: 'orgSug',
      header: multiColOrgHeader,
      format: 'short_name',
      compare: 'id',
    },
    fee_id: {
      type: 'Select',
      label: '费用名称',
      header: ['name'],
      format: 'name',
      compare: 'key',
    },
    batch: {
      type: 'Select',
      label: taskDict,
      filter: 'batchSug',
      header: batchHeader,
      format: 'car_batch',
      compare: 'id',
    },
    tr_id: {
      type: 'Select',
      label: '车牌',
      filter: 'truckSug',
      header: truckDriverHeader,
      format: 'tr_num',
      compare: 'id',
    },
    dr_id: {
      type: 'Select',
      label: '司机',
      filter: 'driverSug',
      header: driverStatusHeader,
      format: 'name',
      compare: 'id',
    },
    happen_time: {
      type: 'Time',
      label: '费用发生时间',
    },
    km_num_entrance: {
      type: 'Input',
      label: '当前里程',
      pattern: FLOAT_P_2,
    },
    amount: {
      type: 'Input',
      label: '金额',
      pattern: FLOAT_P_2,
      computerIcon: 'icon-calculate',
    },
    unit_price: {
      type: 'Input',
      label: '单价',
      pattern: FLOAT_P_2,
      computerIcon: 'icon-calculate',
    },
    quantity: {
      type: 'Input',
      label: '数量',
      pattern: FLOAT_P_2,
      computerIcon: 'icon-calculate',
    },
    site_exit: {
      type: 'DataList',
      label: '站点',
      filter: 'entranceExitOilStationSug',
      map: 'name',
    },
    income_expend_way: {
      type: 'Select',
      label: '支付方式',
    },
    card_num: {
      type: 'DataList',
      label: '卡号',
      filter: 'oilCardSug',
      header: [
        ...multiColOilCardHeader,
        { key: 'driver_name', title: '司机姓名' },
        { key: 'driver_tel', title: '司机手机号' },
        { key: 'truck_num', title: '车牌号' },
      ],
      map: 'card_num',
    },
    custom_field_1: {
      type: 'Input',
      label: ft('尿素自定义字段1'),
    },
    custom_field_2: {
      type: 'Input',
      label: ft('尿素自定义字段2'),
    },
    custom_field_3: {
      type: 'Input',
      label: ft('尿素自定义字段3'),
    },
    payee: {
      type: 'Select',
      label: ft('收款人'),
      filter: 'truckPayeeSug',
      header: [
        { key: 'tr_payee_name', title: '收款人' },
        { key: 'tr_payee_open_bank', title: '开户行' },
        { key: 'tr_payee_bank_card_num', title: '银行卡' },
      ],
      compare: 'id',
      format: 'tr_payee_name',
    },
    remark: {
      type: 'TextArea',
      label: '备注',
      className: 'remark x-100',
    },
    pic: {
      type: 'UploadImgText',
      label: '图片',
      className: 'x-100',
    },
    payment_pics: {
      type: 'UploadImgText',
      label: '付款照片',
      className: 'x-100',
    },
  };

  const inTransitTollFeeFields = {
    company_id: {
      type: 'Select',
      label: '所属组织',
      filter: 'orgSug',
      header: multiColOrgHeader,
      format: 'short_name',
      compare: 'id',
    },
    fee_id: {
      type: 'Select',
      label: '费用名称',
      header: ['name'],
      format: 'name',
      compare: 'key',
    },
    batch: {
      type: 'Select',
      label: taskDict,
      filter: 'batchSug',
      header: batchHeader,
      format: 'car_batch',
      compare: 'id',
    },
    tr_id: {
      type: 'Select',
      label: '车牌',
      filter: 'truckSug',
      header: truckDriverHeader,
      format: 'tr_num',
      compare: 'id',
    },
    dr_id: {
      type: 'Select',
      label: '司机',
      filter: 'driverSug',
      header: driverStatusHeader,
      format: 'name',
      compare: 'id',
    },
    happen_time: {
      type: 'Time',
      label: '费用发生时间',
    },
    amount: {
      type: 'Input',
      label: '金额',
      pattern: FLOAT_P_2,
    },
    km_num_entrance: {
      type: 'DataList',
      label: '入站口',
      filter: 'entranceExitOilStationSug',
      map: 'name',
    },
    site_exit: {
      type: 'DataList',
      label: '出站口',
      filter: 'entranceExitOilStationSug',
      map: 'name',
    },
    income_expend_way: {
      type: 'Select',
      label: '支付方式',
    },
    card_num: {
      type: 'Select',
      label: 'ETC卡号',
      filter: 'etcSug',
      header: multiColETCCardHeader,
      format: 'number',
      compare: 'number',
    },
    load_flag: {
      type: 'Select',
      label: '路桥费空重驶',
    },
    custom_field_1: {
      type: 'Input',
      label: ft('路桥费自定义字段1'),
    },
    custom_field_2: {
      type: 'Input',
      label: ft('路桥费自定义字段2'),
    },
    custom_field_3: {
      type: 'Input',
      label: ft('路桥费自定义字段3'),
    },
    payee: {
      type: 'Select',
      label: ft('收款人'),
      filter: 'truckPayeeSug',
      header: [
        { key: 'tr_payee_name', title: '收款人' },
        { key: 'tr_payee_open_bank', title: '开户行' },
        { key: 'tr_payee_bank_card_num', title: '银行卡' },
      ],
      compare: 'id',
      format: 'tr_payee_name',
    },
    remark: {
      type: 'TextArea',
      label: '备注',
      className: 'remark x-100',
    },
    pic: {
      type: 'UploadImgText',
      label: '图片',
      className: 'x-100',
    },
    payment_pics: {
      type: 'UploadImgText',
      label: '付款照片',
      className: 'x-100',
    },
  };

  const inTransitOtherFeeFields = {
    company_id: {
      type: 'Select',
      label: '所属组织',
      filter: 'orgSug',
      header: multiColOrgHeader,
      format: 'short_name',
      compare: 'id',
    },
    fee_id: {
      type: 'Select',
      label: '费用名称',
      header: ['name'],
      format: 'name',
      compare: 'key',
    },
    batch: {
      type: 'Select',
      label: taskDict,
      filter: 'batchSug',
      header: batchHeader,
      format: 'car_batch',
      compare: 'id',
    },
    tr_id: {
      type: 'Select',
      label: '车牌',
      filter: 'truckSug',
      header: truckDriverHeader,
      format: 'tr_num',
      compare: 'id',
    },
    dr_id: {
      type: 'Select',
      label: '司机',
      filter: 'driverSug',
      header: driverStatusHeader,
      format: 'name',
      compare: 'id',
    },
    happen_time: {
      type: 'Time',
      label: '费用发生时间',
    },
    amount: {
      type: 'Input',
      label: '金额',
      pattern: FLOAT_P_2,
      computerIcon: 'icon-calculate',
    },
    unit_price: {
      type: 'Input',
      label: '单价',
      pattern: FLOAT_P_2,
      computerIcon: 'icon-calculate',
    },
    quantity: {
      type: 'Input',
      label: '数量',
      pattern: FLOAT_P_2,
      computerIcon: 'icon-calculate',
    },
    income_expend_way: {
      type: 'Select',
      label: '支付方式',
    },
    custom_field_1: {
      type: 'Input',
      label: ft('在途其他费自定义字段1'),
    },
    custom_field_2: {
      type: 'Input',
      label: ft('在途其他费自定义字段2'),
    },
    custom_field_3: {
      type: 'Input',
      label: ft('在途其他费自定义字段3'),
    },
    payee: {
      type: 'Select',
      label: ft('收款人'),
      filter: 'truckPayeeSug',
      header: [
        { key: 'tr_payee_name', title: '收款人' },
        { key: 'tr_payee_open_bank', title: '开户行' },
        { key: 'tr_payee_bank_card_num', title: '银行卡' },
      ],
      compare: 'id',
      format: 'tr_payee_name',
    },
    remark: {
      type: 'TextArea',
      label: '备注',
      className: 'remark x-100',
    },
    pic: {
      type: 'UploadImgText',
      label: '图片',
      className: 'x-100',
    },
    payment_pics: {
      type: 'UploadImgText',
      label: '付款照片',
      className: 'x-100',
    },
  };
  return [
    {
      key: 'inTransitOilFee',
      feeType: 8,
      title: ft('油费'),
      settingKey: 'ctt_on_way_fee_oil_fields',
      fields: inTransitOilFeeFields,
    },
    {
      key: 'inTransitUreaFee',
      feeType: 9,
      title: ft('尿素费'),
      settingKey: 'ctt_on_way_fee_urea_fields',
      fields: inTransitUreaFeeFields,
    },
    {
      key: 'inTransitTollFee',
      feeType: 10,
      title: ft('路桥费'),
      settingKey: 'ctt_on_way_fee_road_fields',
      fields: inTransitTollFeeFields,
    },
    {
      key: 'inTransitOtherFee',
      feeType: 11,
      title: ft('其他费'),
      settingKey: 'ctt_on_way_fee_other_fields',
      fields: inTransitOtherFeeFields,
    },
  ];
};
